import type { ImageCarouselComponent, OmitComponentAttrs } from '@cardo/types';
import { generateImgProps, getAlt } from '~/lib/strapiImage';
import AwesomeSlider from 'react-awesome-slider';
import { IoArrowForwardSharp, IoArrowBackSharp } from 'react-icons/io5';
import { ClientOnly } from 'remix-utils/client-only';
import { cn } from '@cardo/lib';

export default function ImageCarousel({
  images,
  className,
}: OmitComponentAttrs<ImageCarouselComponent> & { className?: string }) {
  return (
    <ClientOnly
      fallback={
        <div
          className={cn(
            'bg-gray-50 animate-pulse rounded-xl aspect-h-9 aspect-w-16 w-full mb-7',
            className
          )}
        ></div>
      }
    >
      {() => (
        <div className={cn('w-full pb-6 overflow-hidden', className)}>
          {images.data.length === 1 && (
            <div
              key={images.data[0].id}
              className="w-full flex justify-center items-center overflow-hidden"
            >
              <img
                {...generateImgProps({ data: images.data[0] })}
                alt={getAlt({ data: images.data[0] })}
                className="h-full w-full"
              />
              {images.data[0].attributes.caption &&
                images.data[0].attributes.caption !==
                  images.data[0].attributes.name && (
                  <div className="absolute left-0 bottom-0 bg-white py-0.5 sm:py-1 px-1.5 sm:px-3 text-xs opacity-80 rounded-tr-lg">
                    {images.data[0].attributes.caption}
                  </div>
                )}
            </div>
          )}
          {images.data.length > 1 && (
            <AwesomeSlider
              organicArrows={false}
              buttonContentRight={
                <IoArrowForwardSharp className="sm:hover:text-6xl transition-all text-3xl sm:text-5xl bg-slate-500 bg-opacity-70 rounded-full" />
              }
              buttonContentLeft={
                <IoArrowBackSharp className="sm:hover:text-6xl transition-all text-3xl sm:text-5xl bg-slate-500 bg-opacity-70 rounded-full" />
              }
              infinite={false}
            >
              {images.data.map((image) => (
                <div
                  key={image.id}
                  className="h-full flex justify-center items-center bg-slate-50"
                >
                  <img
                    {...generateImgProps({ data: image })}
                    alt={getAlt({ data: image })}
                    className="object-contain h-full"
                  />
                  {image.attributes.caption &&
                    image.attributes.caption !== image.attributes.name && (
                      <div className="absolute left-0 bottom-0 bg-white py-0.5 sm:py-1 px-1.5 sm:px-3 text-xs opacity-80 rounded-tr-lg">
                        {image.attributes.caption}
                      </div>
                    )}
                </div>
              ))}
            </AwesomeSlider>
          )}
        </div>
      )}
    </ClientOnly>
  );
}
